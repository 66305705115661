.newProduct {
  flex: 5;
}

.addProductForm {
  margin-top: 10px;
}

@media (min-width: 768px) {
  .addProductForm {
    width: 50%;
  }
}

.select-input-container {
  --row-gap: calc(0 * 16pt);
  font-family: "Source Sans Pro", sans-serif;
  margin: 0;
  width: 70%;
  display: inline-flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-box-align: center;
}

.select-input-label {
  --row-gap: calc(0 * 16pt);
  font-family: "Source Sans Pro", sans-serif;
  margin: 0;
  display: block;
  font-weight: normal;
  color: #000;
  padding: 0 0 0 4px;
  margin-bottom: 4pt;
  font-size: 1rem;
  line-height: 1.5;
  user-select: none;
}

.select-container {
  --row-gap: calc(0 * 16pt);
  font-family: "Source Sans Pro", sans-serif;
  margin: 0;
  width: 100%;
  transition: all 0.25s ease;
  border-radius: 14px;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  height: calc(1.875 * 16pt);
}

.select-container-language {
  --row-gap: calc(0 * 16pt);
  font-family: "Source Sans Pro", sans-serif;
  margin: 0;
  width: 10%;
  transition: all 0.25s ease;
  border-radius: 14px;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  height: calc(1.875 * 16pt);
}

.select-wrapper {
  --row-gap: calc(0 * 16pt);
  font-family: "Source Sans Pro", sans-serif;
  background: transparent;
  border: none;
  flex: 1;
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  -webkit-box-align: center;
  align-items: center;
  user-select: none;
  box-shadow: 0 0 0 2px #eaeaea;
  -webkit-transition: box-shadow 0.25s ease;
  transition: box-shadow 0.25s ease;
  height: 100%;
  border-radius: 14px;
}

.next-ui-select {
  --row-gap: calc(0 * 16pt);
  user-select: none;
  font-family: "Source Sans Pro", sans-serif;
  padding: 0;
  box-shadow: none;
  font-size: 1rem;
  background-color: transparent;
  border: none;
  color: #333;
  outline: none;
  border-radius: 0;
  width: 100%;
  min-width: 0;
  margin: 4px 10px;
}

.multi-select-wrapper {
  --row-gap: calc(0 * 16pt);
  font-family: "Source Sans Pro", sans-serif;
  background: transparent;
  border: none;
  flex: 1;
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  -webkit-box-align: center;
  align-items: center;
  user-select: none;
  box-shadow: 0 0 0 2px #eaeaea;
  -webkit-transition: box-shadow 0.25s ease;
  transition: box-shadow 0.25s ease;
  height: 100%;
  border-radius: 14px;
}

.next-ui-multi-select {
  --row-gap: calc(0 * 16pt);
  user-select: none;
  font-family: "Source Sans Pro", sans-serif;
  padding: 0;
  box-shadow: none;
  font-size: 1rem;
  background-color: transparent;
  border: none;
  color: #333;
  outline: none;
  border-radius: 0;
  width: 100%;
  min-width: 0;
}

.next-ui-multi-select > div {
  border: none;
  border-radius: 14px;
}

.next-ui-multi-select > div:hover {
  border-color: #eaeaea !important;
  box-shadow: 0 0 0 1px #eaeaea;
}

.helper-text-container.with-value.helper-text {
  opacity: 1;
}

.helper-text-container {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.helper-text {
  margin: 2px 0 0 10px;
  font-size: 0.7rem;
  color: #f21361;
}

div[id^="react-select-"] {
  overflow: hidden;
}

div[id*="-listbox"] {
  background-color: #fff;
  z-index: 9999;
  border-radius: 14px;
}