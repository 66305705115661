.viewOrder {
    flex: 5;
  }

  .card {
    flex: 1;
    margin: 0.5rem;
    padding: 1rem;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .cart-header {
    display: flex;
    justify-content: space-between;
  }

  .cart-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .card-title {
    font-size: 1.2rem;
  }

  .card-subtitle {
    font-size: 1rem;
  }

  .card-body {
    padding: 0.5rem;
  }

  .table-responsive {
    margin-top: 0.5rem;
    overflow-x: auto;
  }

  .table {
    width: 100%;
    border-collapse: collapse;
  }

  .table th,
  .table td {
    padding: 0.5rem;
    border: 1px solid #ccc;
  }

  .table th {
    font-size: 0.9rem;
    text-align: left;
  }

  .table td {
    font-size: 0.8rem;
  }