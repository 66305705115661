.login-wrapper {
  border: 1px solid #dedede;
  padding: 40px !important;
  max-width: 300px !important;
}

@media screen and (max-width: 480px) {
  .login-wrapper {
    border: none !important;
    padding: 0 !important;
  }
}
