.topbar {
  width: 100%;
  height: 50px;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 999;
}

.topbarWrapper {
  height: 100%;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  font-weight: bold;
  font-size: 30px;
  color: darkblue;
  cursor: pointer;
}

.topRight {
  display: flex;
  align-items: center;
}

.topbarIconContainer {
  position: relative;
  cursor: pointer;
  margin-right: 10px;
  color: #555;
}

.topIconBadge {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: red;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}

.topAvatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}

.sidebarIcon {
  margin-right: 5px;
  font-size: 20px !important;
}

.card-dropdown {
  position: absolute !important;
  top: 50px;
  opacity: 1;
  visibility: visible;
  border-radius: 10px !important;
  box-shadow: 0 8px 30px rgb(0 0 0 / 10%) !important;
}

.card-dropdown > .card-body {
  padding: 0 !important;
}

.card-dropdown > .card-body > span {
  padding: 8pt calc(16pt + 0.25rem) !important;
  cursor: pointer !important;
}

.card-dropdown > .card-body > span:hover {
  background-color: #fbfbff;
}
