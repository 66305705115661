.main-container {
  display: flex;
}

.container {
  max-width: 100% !important;
}

.link {
  text-decoration: none;
  color: inherit;
}

.link-disabled {
  color: #ccc;
}

.modal-header {
  border-bottom: 1px solid #d9d7d7 !important;
}

.MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: none !important;
}

.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

input:focus {
  outline: none !important;
}