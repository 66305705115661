.orderList {
  flex: 5;
  height: 80vh;
}

.orderListItem {
  display: flex;
  align-items: center;
}

.switch {
  border: 2px solid transparent !important;
  background: #eaeaea !important;
  box-shadow: inset 0 0 4px 0 rgb(0 0 0 / 5%);
}

label {
  cursor: default !important;
}

.circle.jsx-2456606884 {
  background: #fff !important;
}

.disabled > .circle {
  background: #fff !important;
}

.checked {
  border: 1px solid transparent !important;
  background: #45d382 !important;
}

.MuiDataGrid-row {
  cursor: pointer;
}