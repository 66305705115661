.notAvailable {
  flex: 5;
  padding: 10px;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: auto !important;
  /*     background-color: #fff;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
    box-shadow: 0px 0px 5px #ccc; */
}
