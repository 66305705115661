.settings {
  flex: 5;
}

.settingsForm {
  margin-top: 10px;
}

@media (min-width: 768px) {
  .settingsForm {
    width: 50%;
  }
}

.select-input-container {
  --row-gap: calc(0 * 16pt);
  font-family: "Source Sans Pro", sans-serif;
  margin: 0;
  width: 70%;
  display: inline-flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-box-align: center;
}

.select-input-label {
  --row-gap: calc(0 * 16pt);
  font-family: "Source Sans Pro", sans-serif;
  margin: 0;
  display: block;
  font-weight: normal;
  color: #000;
  padding: 0 0 0 4px;
  margin-bottom: 4pt;
  font-size: 1rem;
  line-height: 1.5;
  user-select: none;
}

.select-container {
  --row-gap: calc(0 * 16pt);
  font-family: "Source Sans Pro", sans-serif;
  margin: 0;
  width: 100%;
  transition: all 0.25s ease;
  border-radius: 14px;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  height: calc(1.875 * 16pt);
}

.select-container-language {
  --row-gap: calc(0 * 16pt);
  font-family: "Source Sans Pro", sans-serif;
  margin: 0;
  width: 10%;
  transition: all 0.25s ease;
  border-radius: 14px;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  height: calc(1.875 * 16pt);
}

.select-wrapper {
  --row-gap: calc(0 * 16pt);
  font-family: "Source Sans Pro", sans-serif;
  background: transparent;
  border: none;
  flex: 1;
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  -webkit-box-align: center;
  align-items: center;
  user-select: none;
  box-shadow: 0 0 0 2px #eaeaea;
  -webkit-transition: box-shadow 0.25s ease;
  transition: box-shadow 0.25s ease;
  height: 100%;
  border-radius: 14px;
}

.next-ui-select {
  --row-gap: calc(0 * 16pt);
  user-select: none;
  font-family: "Source Sans Pro", sans-serif;
  padding: 0;
  box-shadow: none;
  font-size: 1rem;
  background-color: transparent;
  border: none;
  color: #333;
  outline: none;
  border-radius: 0;
  width: 100%;
  min-width: 0;
  margin: 4px 10px;
}

.multi-select-wrapper {
  --row-gap: calc(0 * 16pt);
  font-family: "Source Sans Pro", sans-serif;
  background: transparent;
  border: none;
  flex: 1;
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  -webkit-box-align: center;
  align-items: center;
  user-select: none;
  box-shadow: 0 0 0 2px #eaeaea;
  -webkit-transition: box-shadow 0.25s ease;
  transition: box-shadow 0.25s ease;
  height: 100%;
  border-radius: 14px;
}

.next-ui-multi-select {
  --row-gap: calc(0 * 16pt);
  user-select: none;
  font-family: "Source Sans Pro", sans-serif;
  padding: 0;
  box-shadow: none;
  font-size: 1rem;
  background-color: transparent;
  border: none;
  color: #333;
  outline: none;
  border-radius: 0;
  width: 100%;
  min-width: 0;
}

.next-ui-multi-select > div {
  border: none;
  border-radius: 14px;
}

.next-ui-multi-select > div:hover {
  border-color: #eaeaea !important;
  box-shadow: 0 0 0 1px #eaeaea;
}

.helper-text-container.with-value.helper-text {
  opacity: 1;
}

.settings .helper-text-container {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
  bottom: calc(1.875 * 11pt * -1) !important;
}

.helper-text {
  margin: 2px 0 0 20px;
  font-size: 0.7rem;
  color: #f21361;
}

.ReactTags__tags {
  position: relative;
  width: 100%;
}

.ReactTags__clearAll {
  cursor: pointer;
  padding: 10px;
  margin: 10px;
  background: #f88d8d;
  color: #fff;
  border: none;
}

/* Styles for the input */
.ReactTags__tagInput {
  height: calc(1.875 * 16pt);
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin: 15px 0px;
  position: relative;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 14px;
  background: transparent;
  border: none;
  box-shadow: 0 0 0 2px #eaeaea;
  -webkit-transition: box-shadow 0.25s ease;
  transition: box-shadow 0.25s ease;
  width: 100%;
}
.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 31px;
  padding: 0;
  box-shadow: none;
  font-size: 1rem;
  background-color: transparent;
  border: none;
  color: #333;
  outline: none;
  border-radius: 0;
  width: 100%;
  min-width: 0;
  -webkit-appearance: none;
  margin: 4px 10px;
}

.ReactTags__editInput {
  border-radius: 1px;
}

.ReactTags__editTagInput {
  display: inline-flex;
}

/* Styles for selected tags */
.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #ddd;
  background: rgb(240, 240, 255);
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  margin: 5px;
  border-radius: 2px;
}
.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer;
}

/* Styles for suggestions */
.ReactTags__suggestions {
  position: absolute;
}
.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 200px;
}
.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}
.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}
.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}

.ReactTags__remove {
  border: none;
  cursor: pointer;
  background: none;
}
